import React from "react";
import { Flex, Box } from "@rebass/grid";
import { kebabCase } from "lodash";
import Helmet from "react-helmet";
import { Link, graphql } from "gatsby";
import Layout from "../../components/Layout";
import H1 from "../../components/H1";
import Container from "../../components/Container";
import Button from "../../components/Button";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title }
    }
  }
}) => (
  <Layout>
    <Container>
      <Helmet title={`Tags | ${title}`} />
      <Flex>
        <Box width={1} mt={6} mb={3}>
          <H1 as="h1">Tags</H1>
        </Box>
      </Flex>
      <Flex wrap="flex-wrap">
        {group.map(tag => (
          <Box pr={2} py={2} key={tag.fieldValue}>
            <Button large as={Link} to={`/tags/${kebabCase(tag.fieldValue)}/`}>
              {tag.fieldValue} ({tag.totalCount})
            </Button>
          </Box>
        ))}
      </Flex>
    </Container>
  </Layout>
);

export default TagsPage;

export const tagPageQuery = graphql`
  query TagsQuery {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 1000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;
